<template>
  <div id="home">
    <van-action-sheet v-model="initializeShow" title="请完善您的信息" :style="{height:'55rem'}">
      <div class="content">
        <Initialize @getInitializeShow="getInitializeShow" />
      </div>
    </van-action-sheet>
    <top-nav-bar>
      <template #left>
        <span>
          <PositionAdress />
        </span>
      </template>
      <template #center>
        <img :src="MEDIA_URL.logo" />
      </template>
      <template #right>
        <van-icon name="comment-o" size="2rem" :style="{marginLeft:'2rem'}" />
      </template>
    </top-nav-bar>
    <!-- <HomeSwiper /> -->
    <!-- <QuickNews /> -->
    <div class="top-search">
      <van-dropdown-menu :style="{marginTop:'5rem'}">
        <van-dropdown-item title="品类排序" ref="item1">
          <div style="padding: 5px 16px;">
            <div class="all-conditions">
              <van-checkbox-group v-model="all_conditions">
                <div class="service_type_list">
                  <van-checkbox v-for="(item,index) in all_conditions_list" :key="index" :name="item.id">
                    <template #icon>
                      {{item.type_name}}
                    </template>
                  </van-checkbox>
                </div>
              </van-checkbox-group>
            </div>
            <div class="public-operation">
              <van-button type="default" size="small" @click="onCancel(1)" style="marginRight:2rem">重新选择</van-button>
              <van-button type="warning" size="small" @click="onConfirm(1)">点击查询</van-button>
            </div>
          </div>
        </van-dropdown-item>
        <van-dropdown-item title="服务品类" ref="item2">
          <div style="padding: 5px 16px;">
            <div class="all-conditions" v-if="service_Categories_list">
              <van-checkbox-group v-model="service_categories_id">
                <div class="service_type_list">
                  <van-checkbox v-for="(item,index) in service_Categories_list" :key="index" :name="item.id">
                    <template #icon>
                      {{item.type_name}}
                    </template>
                  </van-checkbox>
                </div>
              </van-checkbox-group>
            </div>
            <div class="public-operation">
              <van-button type="default" size="small" @click="onCancel(1)" style="marginRight:2rem">重新选择</van-button>
              <van-button type="warning" size="small" @click="onConfirm(2)">点击查询</van-button>
            </div>
          </div>
        </van-dropdown-item>
        <van-dropdown-item title="服务区域" ref="item3">
          <div style="padding: 5px 16px;">
            <div class="all-conditions" v-if="service_areas_list ">
              <van-checkbox-group v-model="service_areas_id ">
                <div class="service_type_list">
                  <van-checkbox v-for="(item,index) in service_areas_list" :key="index" :name="item.county_code">
                    <template #icon>
                      {{item.county_name.name}}
                    </template>
                  </van-checkbox>
                </div>
              </van-checkbox-group>
            </div>
            <div class="public-operation">
              <van-button type="default" size="small" @click="onCancel(1)" style="marginRight:2rem">重新选择</van-button>
              <van-button type="warning" size="small" @click="onConfirm(3)">点击查询</van-button>
            </div>
          </div>
        </van-dropdown-item>
        <van-dropdown-item title="服务类型" ref="item4">
          <div style="padding: 5px 16px;">
            <div class="all-conditions" v-if="service_modes_list">
              <van-checkbox-group v-model="service_modes_id ">
                <div class="service_type_list">
                  <van-checkbox v-for="(item,index) in service_modes_list" :key="index" :name="item.id">
                    <template #icon>
                      {{item.type_name}}
                    </template>
                  </van-checkbox>
                </div>
              </van-checkbox-group>
            </div>
          </div>
          <div class="public-operation">
            <van-button type="default" size="small" @click="onCancel(1)" style="marginRight:2rem">重新选择</van-button>
            <van-button type="warning" size="small" @click="onConfirm(4)">点击查询</van-button>
          </div>
        </van-dropdown-item>
      </van-dropdown-menu>
    </div>
    <OfferMaster :MasterListNum="total" :style="{marginTop:'2rem'}" />
    <van-empty description="请完善信息" v-if="!user_completed_state && JSON.stringify(this.homeAllOrder) != '[]'">
      <van-button round type="danger" class="bottom-button" @click="initializeShow = true">去完善</van-button>
    </van-empty>
    <van-pull-refresh v-model="isLoading" @refresh="onRefresh">
      <van-empty description="暂无订单数据" v-if="JSON.stringify(saleDataList) == '[]'" />
      <van-list v-model="loading" :finished="finished" finished-text="没有更多订单了" @load="onLoad" :offset="offset" class="content" :immediate-check=false v-else>
        <OfferWrap :saleDataList="saleDataList" />
      </van-list>
    </van-pull-refresh>
    <div style="padding-bottom: 60px"></div>
    <BottomNav />
    <van-overlay :show="false" :style="{zIndex:'999'}">
      <div class="overlay" :style="{margin:'1rem'}">
        <p :style="{textAlign:'right'}">
          <van-icon name="cross" color="#fff" size="3rem" @click.stop="initializeShow = false" />
        </p>
        <p :style="{color:'white', fontSize:'1.8rem', textAlign:'center',padding:'2rem'}">请您完善身份信息，方可接单报价</p>
        <Initialize @getInitializeShow="getInitializeShow" />
      </div>
    </van-overlay>
    <loading :show="search_loading" title="筛选中" />
  </div>
</template>
<script>
import { Toast } from "vant";
import BottomNav from "@/components/NavBar/BottomNav.vue";
import HomeSwiper from "@/components/home/HomeSwiper.vue";
import QuickNews from "@/components/home/QuickNews.vue";
import OfferWrap from "@/components/home/OfferWrap.vue";
import OfferMaster from "@/components/home/OfferMaster.vue";
import PositionAdress from "@/components/effect/PositionAdress.vue";
import Initialize from "@/components/Mine/Initialize.vue";

export default {
  name: "Home",
  components: {
    BottomNav,
    HomeSwiper,
    QuickNews,
    OfferWrap,
    PositionAdress,
    OfferMaster,
    Initialize,
  },
  data() {
    return {
      total: 0,
      search_loading: false,
      all_conditions: [],
      service_categories_id: [],
      service_areas_id: [],
      service_modes_id: [],
      publish_type: 2,
      initializeShow: false,
      userPosition: "定位中",
      homeAllOrder: [],
      homeAllOrderTotal: 0,
      indexScrollTop: "",
      homeLoadingTemp: false,
      homeRefresh: false,
      count: 0,
      indexScrollTop: undefined,
      initializeShow: false,
      saleDataList: [],
      list: [],
      isLoading: false,
      loading: false,
      finished: false,
      offset: 15,
      pageNum: 1,
      secret:
        "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJodHRwOlwvXC8xMjcuMC4wLjE6ODg5OVwvYXBpXC9hdXRoXC9sb2dpbiIsImlhdCI6MTYwMTI3OTU1NCwiZXhwIjoxNjAxMjgzMTU0LCJuYmYiOjE2MDEyNzk1NTQsImp0aSI6IjBiV1RJcEE4bllNcGcxUHciLCJzdWIiOjEsInBydiI6IjIzYmQ1Yzg5NDlmNjAwYWRiMzllNzAxYzQwMDg3MmRiN2E1OTc2ZjcifQ.T-4YOofrIfrDbBavuED8WF6q4oFWryROQJD8J-TfUSk",
      all_conditions_list: [
        {
          id: "1",
          type_name: "综合排序",
        },
        {
          id: "2",
          type_name: "品类排序",
        },
        {
          id: "3",
          type_name: "区域排序",
        },
      ],
      service_Categories_list: [],
      service_areas_list: [],
      service_modes_list: [],
      select_type: [
        { text: "待报价", value: 1 },
        { text: "一口价", value: 2 },
      ],
      user_completed_state: undefined,
    };
  },
  created() {
    this.judgeIntegrity();
    this.getList(this.pageNum);
    this.getSearchOrderList();
    this.getLocation();
    this.homeLoadingTemp = true;
  },
  mounted() {
    this.indexScrollTop = this.getScrolltop();
    // console.log(this.indexScrollTop);
  },

  methods: {
    /**
     * 顶部筛选搜索
     */
    onConfirm(num) {
      this.search_loading = true;
      this.$http
        .post("/api/v2/worker/order/search", {
          service_categories_id: this.service_categories_id,
          service_areas_id: this.service_areas_id,
          service_modes_id: this.service_modes_id,
          publish_type: this.publish_type,
        })
        .then((res) => {
          console.log("首页筛选", res);
          console.log(res.data.result.data.total);
          this.total = res.data.result.total;
          this.saleDataList = res.data.result.data;
          console.log(res);
          this.search_loading = false;
        })
        .catch((err) => {
          this.search_loading = false;
          this.$toast(err.error);
        });
      if (num != null) {
        this.$refs[`item${num}`].toggle();
      }
    },
    /**
     * 清除顶部赛选条件
     */
    onCancel() {
      this.service_categories_id = [];
      this.service_areas_id = [];
      this.service_modes_id = [];
      this.publish_type = 1;
    },
    /**
     * 获取所需的搜索信息
     */
    getSearchOrderList() {
      this.$http.get("/api/v2/worker/order/search_condition").then((res) => {
        // console.log("获取所需的搜索信息", service_Categories_list);
        this.service_Categories_list = res.data.condition.categories.concat(
          res.data.condition.second_categories
        );
        this.service_areas_list = res.data.condition.service_areas;
        this.service_modes_list = res.data.condition.service_modes;
        console.log("获取所需的搜索信息", this.service_areas_list);
      });
    },
    getInitializeShow(e) {
      this.initializeShow = false;
    },

    /**
     * 获取订单信列表
     */
    getOrderList() {
      this.$http.get("/api/v2/worker/orders").then((res) => {
        // console.log(res);
        this.homeAllOrder = res.data.orders;
        // Toast("刷新成功");
        this.isLoading = false;
        this.homeLoadingTemp = false;
      });
    },
    /**
     *  判断完整性
     */
    judgeIntegrity() {
      this.$http.get("/api/v2/worker/profile/is_completed").then((res) => {
        console.log("信息完整性", res.data.result);
        if (res.data.result.service.status) {
          this.user_completed_state = res.data.result.service.status;
          this.initializeShow = false;
          this.getOrderList();
        } else {
          this.initializeShow = true;
        }
      });
    },
    /**
     *  请求数据方法
     */
    getList(pageNum) {
      this.$http
        .get("/api/v2/worker/orders?page=" + pageNum)
        .then((res) => {
          console.log(res.data);
          this.list = res.data.result.data;
          this.saleDataList = this.saleDataList.concat(this.list);
          console.log(this.saleDataList);
          // 加载状态结束
          this.loading = false;
          this.isLoading = false;
          // 总共多少数据
          this.total = res.data.result.total;
          // 数据全部加载完成
          if (this.saleDataList.length >= this.total) {
            this.finished = true;
          }
          if (this.total == 0 || this.saleDataList.length < this.tota) {
            this.finished = false;
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    /**
     *  上拉加载方法
     *  页面打开时初始化会调用onLoad方法 如果想去掉初始化调用使用,给List添加属性immediate-check=false
     */
    onLoad() {
      if (this.total > 0) {
        // 调用请求数据方法
        this.pageNum += 1;
        this.getList(this.pageNum);
      } else {
        this.loading = false;
        this.finished = false;
        return;
      }
    },
    /**
     *  下拉刷新方法
     */
    onRefresh() {
      this.loading = true;
      this.pageNum = 1;
      // 调用请求数据方法
      this.saleDataList = [];
      this.getList(this.pageNum);
    },
    /**
     * 选择一口价或者报价订单
     */
    selectPublishType() {
      this.onConfirm();
    },
  },
};
</script>
<style lang="less">
#home {
  .bottom-button {
    width: 160px;
    height: 40px;
  }
  // background: #fff;
  min-height: 100vh;
  .home-message {
    width: 2rem;
    color: #fff;
  }
  .top-search {
    .service_type_list {
      display: flex;
      box-sizing: border-box;
      flex-wrap: wrap;
    }
    .van-checkbox__icon--round {
      padding: 0.3rem 1.1rem;
      background: #efefef;
      border-radius: 10px;
      display: flex;
      justify-content: center;
      align-items: center;
      color: #6d6d6d;
      margin: 0.5rem 0.5rem;
      min-width: 3rem;
      font-size: 1.3rem;
      border: 1px solid #efefef;
    }
    .van-checkbox__icon--checked {
      color: #f14141;
      background: none;
      border: 1px solid #f14141;
    }
    .van-checkbox--disabled {
      cursor: not-allowed;
    }
  }
  .order-number {
    display: flex;
    justify-content: space-between;
    margin: 1rem;
    align-items: center;
    .order-count {
      font-size: 1.4rem;
    }
    .order-type-num {
      span:first-child {
        color: #000;
        font-size: 1.8rem;
        font-weight: 600;
      }
    }
    .van-dropdown-menu__bar {
      background: none;
      box-shadow: none;
      display: flex;
      align-items: center;
      height: 3.5rem;
    }
    .van-popup--top {
      width: 40%;
    }
    .van-dropdown-menu__item {
      height: 2rem;
    }
    .van-dropdown-item__content {
      left: 22rem;
      z-index: 99;
    }
  }
  .public-operation {
    display: flex;
    justify-content: flex-end;
  }
  .all-conditions {
    display: flex;
    padding: 2rem 0.2rem;
  }
}
</style>
