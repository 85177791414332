<template>
  <div id="QuickNews">
    <van-notice-bar left-icon="volume-o" text="亿师傅安装平台, 0抽佣, 0保证金,即可接单" background="#fff" />
  </div>
</template>
<script>
export default {};
</script>
<style lang="less">
#QuickNews {
  width: 95%;
  height: 4rem;
  box-shadow: 0 0 10px 0 #e4e4e4;
  margin: 6rem auto;
  
}
</style>