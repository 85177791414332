<template>
  <div id="homeSwiper">
    <van-swipe class="my-swipe" :autoplay="3000" indicator-color="white">
      <van-swipe-item class="public-swipe" v-for="(item,index) in swiper_img_list" :key="index" :style="`background: url(${cdnHttp}${item}) no-repeat center / cover`">
      </van-swipe-item>
    </van-swipe>
  </div>
</template>
<script>
export default {
  data() {
    return {
      swiper_img_list: [
        "workerHead/20201104103958rx8lDBVg.jpeg",
        "workerHead/20201104103958rx8lDBVg.jpeg",
        "workerHead/20201104103958rx8lDBVg.jpeg",
        "workerHead/20201104103958rx8lDBVg.jpeg",
      ],
    };
  },
};
</script>
<style lang="less">
#homeSwiper {
  width: 95%;
  margin: 0.5rem auto;
  border-radius: 10px;
  .van-swipe__track {
    margin-top: 45px;
  }
  .swipe {
    border-radius: 10px;
  }
  .my-swipe {
    .public-swipe {
      width: 100%;
      border-radius: 10px;
      height: 12rem;
      background: #000;
      img {
        width: 100%;
        height: 100%;
        border-radius: 10px;
      }
    }
  }
}
</style>