<template>
  <div id="offerWrap">
    <div class="wrapper">
      <!-- <div class="home-order-list " v-for="(item, index) in saleDataList" :key="index" @click="goSubPage('quoting_detail',item.id)"> -->
      <div class="home-order-list " v-for="(item, index) in saleDataList" :key="index" @click="goSubPage(item.publish_type == '2' ? 'accept_detail' : 'quoting_detail', item.id)">
        <div class="order-item">
          <div class="order-title">
            <div class="left-service">
              <span> {{ item.service_mode_name }}</span>
              <span>{{item.category_name}}</span>
            </div>
            <div class="right-slot">
              <van-count-down :time="new Date(item.closed_at).getTime() - new Date().getTime()" format="HH小时后停止报价" />
            </div>
          </div>
          <div class="order-content">
            <div class="left-order-image" :style="item.product_images?`background:url('${cdnHttp}${item.product_images.split(',')[0]}?imageView2/1/format/webp/') no-repeat center / cover`:'background: red'" @click.stop="imagePreview(item.product_images)"></div>
            <div class="right-order-info">
              <p class="text-overflow-line line2 order-address">
                {{item.full_address}}
              </p>
              <div class="quote-count-wrap">
                <p class="quote-count">剩余名额 {{item.worker_count}}个</p>
                <p v-if="item.publish_type == '2'" class="fixed-price">一口价¥{{item.fixed_price}}</p>
              </div>
              <div>
                <span class="tag" v-if="item.logistics_status == 0">
                  <van-tag color="#FFAB5B" round>已到货</van-tag>
                </span>
                <span class="tag" v-else>
                  <van-tag plain color="#FFAB5B" round>未到货</van-tag>
                </span>
                <span class="tag" v-if="item.elevator == 0">
                  <van-tag color="#FFAB5B" round>有电梯</van-tag>
                </span>
                <span class="tag" v-if="item.floor && item.elevator == 1">
                  <van-tag color="#FFAB5B" round>3层</van-tag>
                </span>
              </div>
            </div>
          </div>
          <div class="order-operation">
            <div class="left-slot"></div>
            <div class="right-slot">
              <van-button round plain size="small" color="#FF7963" :style="{height:'2.4rem'}">
                {{item.publish_type == '2' ? '立即接单' : '立即报价'}}
              </van-button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Vue from "vue";
import Initialize from "@/components/Mine/Initialize.vue";
import { PullRefresh, Toast, List, Cell } from "vant";
import { ImagePreview } from "vant";
Vue.use(PullRefresh).use(Toast).use(List).use(Cell);
export default {
  props: {
    saleDataList: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  data() {
    return {
      initializeShow: false,
      loadingTemp: false,
      list: [],
      isLoading: false,
      loading: false,
      finished: false,
      offset: 15,
      pageNum: 1,
    };
  },
  components: {
    Initialize,
  },
  created() {},
  methods: {
    /**
     * 图片预览
     */
    imagePreview(imgList) {
      let _imgList = [];
      _imgList = imgList.split(",").map((option) => this.cdnHttp + option);
      ImagePreview({ images: _imgList, showIndex: false, closeable: true });
    },
  },
};
</script>
<style lang="less">
#offerWrap {
  .van-overlay {
    background: rgba(0, 0, 0, 0.2);
  }
}
</style>
<style scoped lang="less">
#offerWrap {
  touch-action: pay-y !important;
  width: 95%;
  margin: 0 auto;
  font-size: 1.4rem;
  margin-top: 1rem;
  transition: all 0.1s ease;
  z-index: 1;

  .home-order-list {
    .order-item {
      margin: 1rem 0.1rem;
      min-height: 15rem;
      background: #fff;
      border-radius: 8px;
      padding: 1rem;
      .order-title {
        display: flex;
        justify-content: space-between;
        margin-bottom: 1.2rem;
        align-items: center;
        .left-service {
          font-weight: 600;
          span {
            margin-right: 0.5rem;
            font-size: @theme-font-size-m;
            color: @themeFontColor;
          }
          span:first-child {
            font-size: @theme-font-size-l;
            color: #000;
          }
        }
        .right-slot {
          .van-count-down {
            color: #ff7336;
            font-size: @theme-font-size-s;
          }
        }
      }

      .order-content {
        width: 100%;
        display: flex;
        .left-order-image {
          width: 9rem;
          height: 9rem;
          background: chocolate;
          border-radius: 10px;
        }
        .right-order-info {
          flex: 1;
          height: 9rem;
          display: flex;
          flex-direction: column;
          padding: 0 1rem;
          .order-address {
            font-size: 1.4rem;
            line-height: 2rem;
            margin-bottom: 0.4rem;
          }
          .quote-count-wrap {
            display: flex;
            justify-content: space-between;
            font-size: @theme-font-size-s;
            align-items: center;
            margin-bottom: 0.4rem;
            .fixed-price {
              font-weight: 900;
              color: @filpDown;
            }
          }
          .quote-count {
            color: #adadad;
            font-size: @theme-font-size-s;
          }
          .tag {
            margin-right: 0.4rem;
            .van-tag--round {
              padding: 0 0.6rem;
              font-size: 1.1rem;
            }
          }
          .rate_fee {
            display: flex;
            align-items: center;
            .back {
              width: 2rem;
              height: 2rem;
              display: inline-block;
              background: #ff434d;
              text-align: center;
              color: #fff;
              font-size: @theme-font-size-s;
              line-height: 2rem;
              margin-right: 1rem;
            }
            .moment {
              color: #ff976a;
            }
          }
        }
      }
      .order-operation {
        display: flex;
        justify-content: space-between;
      }
    }
  }
}
</style>

