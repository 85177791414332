<template>
  <div id="moreUserInfo">
    <div class="cellWrap">
      <!-- <van-cell title="头像" size="large" center>
        <van-uploader :after-read="avatarUpLoad">
          <div class="avatarUpLoad" v-if="avatarLoading">
            <img :src="avatarUrl" alt="">
          </div>
          <div class="avatarUpLoad" v-else>
            <van-loading type="spinner" />
          </div>
        </van-uploader>
      </van-cell> -->
      <van-popup v-model="picShow" closeable close-icon-position="top-right" position="bottom" :style="{ height: '30%' }">
      </van-popup>
      <!-- 地址选择 -->
      <van-popup v-model="AdressShow" position="bottom" :style="{ height: '80%' }" visible-item-count="6">
        <!-- <van-area value="310101" title="请选择地址" :area-list="areaList" :columns-placeholder="['请选择', '请选择', '请选择']" loading: true @cancel="cancel" @confirm="confirm" /> -->
        <SelectAdress @submitAdress="getResultAdress" @cancel="() => {AdressShow = false}" />
      </van-popup>
      <!-- 服务类目 -->
      <van-popup v-model="serviceShow" position="bottom" :style="{ height: '80%' }">
        <div class="propWrap">
          <tool-bar leftTitle="取消" rightTitle="确定" title="服务类目" @confirm="serviceShow = false" @cancel="() => {serviceShow = false}" />
          <van-collapse v-model="activeName" accordion>
            <select-service-type @resultServiceType="getResultServiceType" />

          </van-collapse>
        </div>
      </van-popup>
      <!-- 服务类型弹出层 -->
      <van-popup v-model="serviceTypeShow" position="bottom" :style="{ height: '60%' }">
        <tool-bar leftTitle="全选" rightTitle="确认" title="服务类型" @confirm="serviceTypeRight" @cancel="checkAll" />
        <div class="propWrap">
          <van-checkbox-group v-model="result" @change="changeType" ref="checkboxGroup">
            <van-cell-group>
              <van-cell v-for="(item, index) in list" clickable :key="index" :title="`${item.type_name}`" @click="toggle(index)">
                <template #right-icon>
                  <van-checkbox :name="item" ref="checkboxes" checked-color="#FF816C" />
                </template>
              </van-cell>
            </van-cell-group>
          </van-checkbox-group>
        </div>
      </van-popup>
      <van-cell title="服务区域" icon="location-o">
        <!-- 使用 right-icon 插槽来自定义右侧图标 -->
        <template #right-icon>
          <span @click="changeAdress" class="fontWrap">{{ userAddressInfo }}</span>
        </template>
      </van-cell>
      <van-cell title="服务类目" icon="orders-o">
        <!-- 使用 right-icon 插槽来自定义右侧图标 -->
        <template #right-icon>
          <span @click="changeService" class="fontWrap">{{ serviceInfo }}</span>
        </template>
      </van-cell>
      <van-cell title="服务类型" icon="flag-o" style="white-space: nowrap">
        <template #right-icon>
          <span @click="changeServiceType" class="fontWrap">
            {{serviceTypeInfo}}
          </span>
        </template>
      </van-cell>
      <van-cell title="常驻地址" icon="hotel-o">
        <van-popup v-model="alsoAddressShow" position="bottom" :style="{ height: '60%' }" visible-item-count="2">
          <van-area value="310101" title="请选择地址" :area-list="areaList" :columns-placeholder="['请选择', '请选择', '请选择']" loading: true @cancel="alsoAddressLeft" @confirm="alsoAddressRight" />
        </van-popup>
        <!-- 使用 right-icon 插槽来自定义右侧图标 -->
        <template #right-icon> <span @click="changealsoAdress" class="fontWrap">{{alsoAdressInfo}}</span> </template>
      </van-cell>
      <van-cell title="详细地址" icon="bag-o">
        <template #right-icon>
          <input type="text" placeholder="输入详细地址" class="urgentPhone" v-model="detailAdressInfo" />
        </template>
      </van-cell>
      <van-cell title="紧急联系人电话" icon="phone-o">
        <!-- 使用 right-icon 插槽来自定义右侧图标 -->
        <template #right-icon>
          <input type="text" placeholder="输入手机号" class="urgentPhone" v-model="urgentPhone" maxlength="11" />
        </template>
      </van-cell>
    </div>
    <div class="bottomButton" @click="submitMoreInfo">点击提交</div>
  </div>
</template>
<script>
import selectServiceType from "@/components/Mine/baseInfo/selectServiceType.vue";
import SelectAdress from "@/components/effect/SelectAdress.vue";
import TopBar from "@/components/home/TopBar.vue";
import ToolBar from "@/components/effect/ToolBar.vue";
import add from "@/assets/area.js";
import { Toast } from "vant";
export default {
  data() {
    return {
      activeIds: [1, 2, 3],
      activeIndex: 0,

      serviceInfo: "选择服务类目 >",
      serviceInfoArr: [],
      serviceShow: false,
      service_categories: [],
      serviceObj: [],

      copyServiceObj_main: [],
      copyServiceObj_add: [],
      activeArrA: [],
      activeArrB: [],
      activeName: 1,

      AdressShow: false,
      userAddress: [],
      userAddressInfo: "选择服务地区 >",
      false1: false,

      serviceTypeShow: false,
      serviceTypeInfo: "选择服务类型 >",
      list: [],
      listShow: false,
      result: [],
      newResult: [],
      alsoAddressShow: false,
      alsoAdress: {},
      alsoAdressInfo: "选择常驻地址 >",
      detailAdressInfo: "",
      urgentPhone: "",
      avatarLoading: true,
      areaList: add,
      dotTemp: "",
      maxSelect: 3,
      num: 0,
      flag: true,
      picShow: false,
      avatarUrl: require("../../assets/images/touxiang.png"),
      avatar: "dss",
    };
  },
  components: {
    TopBar,
    SelectAdress,
    selectServiceType,
    ToolBar,
  },

  created() {
    // 服务类型接口获取
    this.$http.get(`api/common/serviceMode?id=1`).then((res) => {
      this.list = res.data;
    });
  },
  mounted() {},
  methods: {
    adressShowCancel() {
      this.AdressShow = false;
    },
    getResultServiceType(e) {
      this.serviceInfo = e.cn;
      this.service_categories = e.all;
    },
    // 请求上传头像
    avatarUpLoad(e) {
      console.log(e);
      let imageFormData = new FormData();
      imageFormData.append("files", e.file);
      // console.log(e, "ss");
      this.avatarLoading = false;
      this.$http
        .post("/api/worker/uploadAvatar", imageFormData, {
          headers: {
            "content-type": "multipart/form-data",
            Authorization: `bearer ${window.localStorage.getItem("token")}`,
          },
        })
        .then((res) => {
          console.log(res);
          this.avatarId = res.data.result.url;
          this.avatarLoading = true;
          this.avatarUrl = `${this.cdnHttp}${res.data.result.url}?imageView2/1/format/webp/w/110/h/110/q/90`;
          this.avatar = res.data.result.url;
        });
    },
    activeArrTrue1(e) {
      this.activeArrA = e;
      console.log("主分类选中的列表", this.activeArrA);
      this.copyServiceObj_add.forEach((item, index) => {
        this.activeArrA.forEach((el, i) => {
          if (item.id == el.id) {
            this.copyServiceObj_add.remove(item);
          }
        });
      });
      this.activeArrA.forEach((item, index) => {
        this.activeArrB.forEach((el, i) => {
          if (item.id == el.id) {
            this.activeArrB.remove(el);
          }
        });
      });
    },
    activeArrFalse1(e) {
      this.copyServiceObj_add.push(e);
    },
    activeArrTrue2(e) {
      this.activeArrB = e;
      console.log("次分类选中的列表", this.activeArrB);
    },
    activeArrFalse2(e) {
      console.log("次分类选中的列表", this.activeArrB);
    },
    reviewServiceMenu() {
      this.serviceShow = false;
    },
    getResultAdress(e) {
      console.log("地址选择器返回", e);
      this.AdressShow = e[0];
      this.userAddressInfo = e[2];
      this.userAddress = e[1].selected_county;
    },
    changeService() {
      this.serviceShow = true;
    },
    changeAdress() {
      this.AdressShow = true;
    },
    changeServiceType() {
      this.serviceTypeShow = true;
    },
    changealsoAdress() {
      this.alsoAddressShow = true;
    },
    serviceAddressCancel(e) {
      console.log(e);
      this.AdressShow = false;
    },
    cancel() {
      this.AdressShow = false;
    },
    // confirm(res) {
    //   // console.log(res);
    //   this.userAddress = res;
    //   this.userAddressInfo = `${this.userAddress[0].name}  ${this.userAddress[1].name}  ${this.userAddress[2].name} `;
    //   this.AdressShow = false;
    // },

    toggle(index) {
      this.$refs.checkboxes[index].toggle();
    },
    checkAll() {
      this.$refs.checkboxGroup.toggleAll(true);
    },
    serviceTypeRight() {
      let resultList = [];

      this.result.forEach((item, index) => {
        resultList.push(item.type_name);
        this.newResult.push(item.id);
      });
      this.serviceTypeInfo = resultList.join(",");
      this.serviceTypeShow = false;
    },
    changeType() {
      // console.log(this.result, "选中的服务类型");
      // this.serviceTypeInfo = this.result.join(",");
      // console.log(this.serviceTypeInfo);
    },
    alsoAddressLeft() {
      this.alsoAddressShow = false;
    },
    alsoAddressRight(res) {
      // console.log(res, "also");
      this.alsoAdress = res;
      this.alsoAdressInfo = `${res[0].name}  ${res[1].name}  ${res[2].name} `;
      this.alsoAddressShow = false;
    },
    submitMoreInfo() {
      // console.log(this.userAddress[0].code);
      // console.log(this.service_categories.join());
      // console.log(this.newResult.join());
      if (this.userAddress.length == 0) {
        this.$notify("请填写完整服务区域");
        // Toast("请填写完整服务区域");
      } else if (this.service_categories.length == 0) {
        this.$notify("请填写完整服务类目");
        // Toast("请填写完整服务类目");
      } else if (this.serviceTypeInfo.length == 0) {
        this.$notify("请填写完整服务类型");
        // Toast("请填写完整服务类型");
      } else if (this.alsoAdress.length != 3) {
        this.$notify("请填写完整常驻地址");
        // Toast("请填写完整常驻地址");
      } else if (this.detailAdressInfo.length == 0) {
        this.$notify("请填写完整详细地址");
        // Toast("请填写完整详细地址");
      } else if (!/^1[3456789]\d{9}$/.test(this.urgentPhone)) {
        this.$notify("请输入正确的手机号码");
        // Toast("请输入正确的手机号码");
      } else {
        this.$http
          .post("api/v2/worker/profile", {
            service_areas: this.userAddress,
            service_categories: this.service_categories.join(","),
            service_modes: this.newResult.join(","),
            address_province_code: this.alsoAdress[0].code,
            address_city_code: this.alsoAdress[1].code,
            address_county_code: this.alsoAdress[2].code,
            address_detail: this.detailAdressInfo,
            urgent_contact_mobile: this.urgentPhone,
            avatar: this.avatar,
          })
          .then((res) => {
            console.log(res);
            if (res.data.code === 0) {
              Toast("提交成功");
              this.$emit("getInitializeShow", false);
            }
          });
      }
    },
  },
};
</script>
<style lang="less">
#moreUserInfo {
  .van-cell {
    box-sizing: border-box;
  }
  .van-cell--large {
    padding-top: 0;
  }
  .avatarUpLoad {
    width: 5.5rem;
    height: 5.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    img {
      width: 100%;
      border-radius: 1000px;
    }
  }
  .tips {
    margin-top: 45px;
    padding: 1.5rem;
    text-align: center;
    font-size: 1.4rem;
  }
  .cellWrap {
    // margin-top: 45px;
    box-sizing: border-box;
    width: 95%;
    margin: 0 auto;
    padding: 2rem 0rem;
    background: #fff;
    border-radius: 10px;
    .urgentPhone {
      width: 50%;
      border: none;
    }
  }
  .serviceType {
    width: 100%;
    // margin-top: 3rem;
    .selectedType {
      font-size: 1.7rem;
      margin: 2rem 0;
      span {
        color: red;
      }
    }
  }
  .avan-grid-item__text {
    color: red;
    font-weight: bold;
  }
  .van-grid {
    padding-top: 2rem;
  }
  .serviceButton {
    width: 70%;
    margin: 0 auto;
  }
  .propWrap {
    // margin: 1rem;
    box-sizing: border-box;
  }
  .fontWrap {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    font-size: 1.4rem;
    width: 40%;
    text-align: right;
  }
  .van-picker__title,
  .van-picker__confirm {
    color: #000;
  }
  .van-cell__title {
    white-space: nowrap;
  }
  .bottomButton {
    width: 90%;
    // margin-bottom: 160px;
    // margin-bottom: 60px;
    margin: 0 auto;
    border-radius: 10rem;
    background: linear-gradient(to right, #ff6034, #ee0a24);
    height: 50px;
    font-size: 1.7rem;
    // font-weight: 700;
    color: #fff;
    text-align: center;
    line-height: 50px;
    margin-top: 3rem;
  }
}
</style>